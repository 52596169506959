const schema = {
  balanceEntries: {
    type: "balanceEntries",
    fields: {
      id: "number",
      amount: "number",
      balanceEvent: "number",
      balanceRegistration: "number",
      balanceStuff: "number",
      timestamp: "date",
    },
    relationships: {
      person: {
        type: "people",
      },
    },
  },
  cars: {
    type: "cars",
    fields: {
      id: "number",
    },
    relationships: {
      person: {
        type: "people",
      },
    },
  },
  carReports: {
    type: "carReports",
    fields: {
      id: "number",
      date: "date",
      noneventDate: "date",
    },
    relationships: {
      car: {
        type: "cars",
      },
      event: {
        type: "events",
      },
      driver: {
        type: "people",
      },
      passengers: {
        type: "people",
      },
      transportOffer: {
        type: "transportOffers",
      },
    },
  },
  events: {
    type: "events",
    fields: {
      id: "number",
      date: "date",
    },
  },
  eventOrganizations: {
    type: "eventOrganizations",
    fields: {
      id: "number",
    },
  },
  eventParticipations: {
    type: "eventParticipations",
    fields: {
      id: "number",
    },
  },
  families: {
    type: "families",
    fields: {
      id: "number",
    },
  },
  familyMemberships: {
    type: "familyMemberships",
    fields: {
      id: "number",
    },
  },
  payments: {
    type: "payments",
    fields: {
      id: "number",
      timestamp: "date",
    },
    relationships: {
      event: {
        type: "events",
      },
      person: {
        type: "people",
      },
    },
  },
  people: {
    type: "people",
    fields: {
      id: "number",
    },
  },
  registrations: {
    type: "registrations",
    fields: {
      id: "number",
    },
  },
  registrationLevels: {
    type: "registrationLevels",
    fields: {
      id: "number",
    },
  },
  seasons: {
    type: "seasons",
    fields: {
      id: "number",
    },
  },
  transportOfferJoins: {
    type: "transportOfferJoins",
    fields: {
      id: "number",
    },
    relationships: {
      person: {
        type: "people",
      },
    },
  },
  transportOffers: {
    type: "transportOffers",
    fields: {
      id: "number",
    },
    relationships: {
      car: {
        type: "cars",
      },
      event: {
        type: "events",
      },
      person: {
        type: "people",
      },
      people: {
        type: "people",
      },
      transportOfferJoins: {
        type: "transportOfferJoins",
      },
    },
  },
};

export default schema;
