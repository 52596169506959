import { useMutation } from "jsonapi-react";
import Button from "react-bootstrap/lib/Button";
import Overlay from "react-bootstrap/lib/Overlay";
import Tooltip from "react-bootstrap/lib/Tooltip";
import React, { useRef, useState } from "react";

const LoadBank = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [mutate, mutation] = useMutation("payments");
  const buttonRef = useRef(null);

  const tooltip = () => {
    if (mutation.isLoading) {
      return (
        <>
          <i className="fa fa-spinner fa-spin" data-show="tooltip" /> Nahrávám
        </>
      );
    }

    if (mutation.error) {
      setTimeout(() => setShowTooltip(false), 3000);
      return <>{mutation.error.title}</>;
    }

    if (mutation.data) {
      setTimeout(() => setShowTooltip(false), 3000);
      return <>Nahráno nových plateb: {mutation.data.length}</>;
    }

    return <></>;
  };

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={() => {
          setShowTooltip(true);
          mutate({ loadBank: true });
        }}
      >
        Aktualizovat
      </Button>
      <Overlay
        show={showTooltip}
        placement="bottom"
        container={this}
        target={buttonRef.current}
      >
        <Tooltip id="loading">{tooltip()}</Tooltip>
      </Overlay>
    </>
  );
};

export default LoadBank;
