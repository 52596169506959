import { useState } from "react";

const getHash = () => {
  const hash = window.location.hash;
  return hash.charAt(0) == "#" ? hash.slice(1) : hash;
};

const urlSet = (key, value) => {
  let existing = new URLSearchParams(getHash());
  existing.set(key, value);
  history.replaceState(history.state, "", "#" + existing.toString());
};

const urlGet = (key) => {
  return new URLSearchParams(getHash()).get(key);
};

const fromString = (type, value) => {
  switch (type) {
    case "string":
      return value;
    case "number":
      return Number(value);
    case "boolean":
      return value == "true";
  }
  throw `Unexpected type: ${type}`;
};

const useUrlHashState = (key, initial) => {
  const existing = fromString(typeof initial, urlGet(key));
  const [value, useStateSetter] = useState(existing || initial);
  const wrappedSetter = (newValue) => {
    urlSet(key, newValue);
    useStateSetter(newValue);
  };
  return [value, wrappedSetter];
};

const historySetKey = (key, value) => {
  let state =
    typeof history.state === "object" ? Object.assign({}, history.state) : {};
  state[key] = value;
  history.replaceState(state, history.title);
};

const historyGetKey = (key) => {
  return history.state === null ? null : history.state[key];
};

const historyClearKey = (key) => {
  let state = Object.assign({}, history.state);
  delete state[key];
  history.replaceState(state, history.title);
};

export { useUrlHashState, historySetKey, historyGetKey, historyClearKey };
