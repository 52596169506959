import "./BalanceEntryTable.css";
import Badge from "react-bootstrap/lib/Badge";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Collapse from "react-bootstrap/lib/Collapse";
import Constants from "../util/Constants";
import EditableText from "../util/EditableText";
import Label from "react-bootstrap/lib/Label";
import PropTypes from "prop-types";
import React, { useRef, useContext, useState } from "react";
import RestoreScrolling from "../util/RestoreScrolling";
import Row from "react-bootstrap/lib/Row";
import styled from "styled-components";
import { formatCost } from "../util/CostFormat";
import { makeQueryErrorAlerts } from "../util/Errors";
import { useIsVisible } from "react-is-visible";
import { useQuery } from "jsonapi-react";

const BalanceRow = (props) => {
  return <Row className="balance-row">{props.children}</Row>;
};

const BlockFloatLeft = styled.div`
  display: block;
  float: left;
`;

const BlockFloatRight = styled.div`
  display: block;
  float: right;
`;
const InlineBlock = styled.div`
  display: inline-block;
`;

const SumDiv = styled.div`
  border-top: 1px solid;
`;

const isSameDay = (a, b) =>
  a.getFullYear() == b.getFullYear() &&
  a.getDay() == b.getDay() &&
  a.getMonth() == b.getMonth();

const SectionType = {
  DEFAULT: 0,
  POSITIVE_PAYMENT: 1,
  REBALANCE: 2,
};

function getEntrySectionType(entry) {
  if (
    entry.sourceType === "Payment" &&
    entry.amount > 0 &&
    !!entry.sourcePayment?.bank
  ) {
    return SectionType.POSITIVE_PAYMENT;
  } else if (entry.sourceType === null) {
    return SectionType.REBALANCE;
  } else {
    return SectionType.DEFAULT;
  }
}

const formatDate = (date, year = false) => {
  return `${date.getDate()}.\u00a0${date.getMonth() + 1}.${
    year ? "\u00a0" + date.getFullYear() : ""
  }`;
};

const getClassNameByEntryType = (entryType) => {
  switch (entryType) {
    case "registration":
      return "text-warning";
    case "event":
      return "text-primary";
    case "stuff":
      return "text-danger";
    default:
      throw `Unknown entry type ${entryType}.`;
  }
};

const getSpecFromGroup = (group) => {
  const sourceType = group.sourceType;
  switch (sourceType) {
    case "Registration":
      return {
        id: 0,
        label: "warning",
        type: "registration",
        desc: "Příspěvky",
      };
    case "Event":
      return { id: 1, label: "primary", type: "event", desc: "Závod" };
    case "Payment":
      return group.entries[0].sourcePayment?.amount > 0
        ? { id: 2, label: "success", type: "payment", desc: "Platba" }
        : { id: 3, label: "danger", type: "debt", desc: "Dluh" };
    case "Family":
    case "FamilyMembership":
      return { id: 4, label: "info", type: "family", desc: "Rodina" };
    case null:
      // Special entry (e.g., rebalance).
      return { id: 5, label: "default", type: "other", desc: "Ostatní" };
    default:
      throw `Unknown source type ${group.sourceType}.`;
  }
};

const EventDetail = (props) => {
  const detail = useQuery([
    "events",
    props.event.id,
    "eventParticipations",
    {
      filter: {
        personId: props.person.id,
      },
      extraFields: { eventParticipations: "dueExplanation" },
    },
  ]);

  const errors = makeQueryErrorAlerts([detail]);
  if (errors.length) return errors;
  if (detail.isLoading) return <i className="fa fa-spinner fa-spin" />;
  if (detail.data.length == 0) return <>Odhlášen(a)</>;
  return detail.data[0].dueExplanation
    .split("\n")
    .map((fragment, index) => <div key={index}>{fragment}</div>);
};

const Event = (props) => {
  const event = props.event;
  // Deleted event. Probably should not happen very often.
  if (!event) return "";
  return (
    <>
      <div>
        <a href={event.linkSelf}>{event.name}</a>{" "}
      </div>
      {props.isVisible && <EventDetail event={event} person={props.person} />}
    </>
  );
};

const Discounts = (props) => {
  const eventOrganizations = useQuery([
    "people",
    props.person.id,
    "eventOrganizations",
    {
      include: ["event"],
      filter: {
        in_year: props.year,
      },
    },
  ]);

  const errors = makeQueryErrorAlerts([eventOrganizations]);
  if (errors.length) return errors;

  if (eventOrganizations.isLoading) {
    return <i className="fa fa-spinner fa-spin" />;
  }

  if (eventOrganizations.data.length === 0) {
    return "Žádné slevy z pořádání";
  }

  return eventOrganizations.data.map((org) => (
    <div key={org.id}>
      Sleva z pořádání <a href={org.event.linkSelf}>{org.event.title}</a>:{" "}
      <b>{formatCost(org.discount || 0)}</b>
    </div>
  ));
};

const Registration = (props) => {
  const registration = props.registration;
  // Deleted registration.
  if (!registration) return "";
  return (
    <>
      <div>
        Úroveň: <b>{registration.registrationLevel.name}</b>
      </div>
      <div>
        Předpis na příspěvky: <b>{formatCost(registration.fee)}</b>
      </div>
      <div>
        {props.isVisible && (
          <Discounts person={props.person} year={registration.year - 1} />
        )}
      </div>
    </>
  );
};

const Payment = (props) => {
  const entry = props.entry;
  const payment = entry.sourcePayment;
  const constants = useContext(Constants);
  // Deleted payment.
  if (!payment) return "";
  return (
    <>
      <div>
        {payment.bank ? (
          <>Variabilní symbol: {payment.variableSymbol}</>
        ) : (
          <>Pseudoplatba</>
        )}{" "}
        <b>({constants.paymentTargets[payment.target]})</b>
      </div>
      <div>
        Částka: <b>{formatCost(payment.amount)}</b>
      </div>
      {!isSameDay(payment.timestamp, entry.timestamp) && (
        <div>Platba z {formatDate(payment.timestamp)}</div>
      )}
      {payment.note && (
        <div>
          <code>{payment.note}</code>
        </div>
      )}
    </>
  );
};

const Summary = (props) => {
  const entry = props.entry;
  return (
    <>
      <BlockFloatLeft className="balance-cell-header">&nbsp;</BlockFloatLeft>
      <BlockFloatRight className="balance-cell-grey">
        <BlockFloatLeft className="w75px text-right">
          <div>Příspěvky</div>
          <div>Závody</div>
          <div>Materiál</div>
          <SumDiv>Celkem</SumDiv>
        </BlockFloatLeft>
        <BlockFloatLeft className="w75px text-right">
          <div className="text-warning">
            {formatCost(entry.balanceRegistration)}
          </div>
          <div className="text-primary">{formatCost(entry.balanceEvent)}</div>
          <div className="text-danger">{formatCost(entry.balanceStuff)}</div>
          <SumDiv>
            <b>
              {formatCost(
                entry.balanceRegistration +
                  entry.balanceEvent +
                  entry.balanceStuff
              )}
            </b>
          </SumDiv>
        </BlockFloatLeft>
      </BlockFloatRight>
      <div>{props.label}</div>
    </>
  );
};

const formatNoteLabel = (entry) => {
  return `Poznámka k položce z ${formatDate(entry.timestamp)}`;
};

const Note = (props) => {
  const entry = props.entry;
  if (!entry.permissions.editable && !props.showValue) {
    return "";
  }
  return (
    <EditableText
      currentValue={entry.note}
      showValue={props.showValue}
      dashedLink={props.showValue}
      editable={entry.permissions.editable}
      label={formatNoteLabel(entry)}
      missingText={
        <i
          className="fa fa-pencil"
          data-show="tooltip"
          data-title="Zadat nebo upravit poznámku."
        />
      }
      url={["balanceEntries", entry.id]}
      patchKey="note"
      onResponse={(entry) => props.onUpdate(entry)}
    />
  );
};

const Entry = (props) => {
  const entry = props.entry;
  const wrongYear = entry.timestamp.getFullYear() !== props.year;
  const MaybeTooltip = (props) => {
    if (wrongYear) {
      return (
        <div
          className="opacity-half"
          data-show="tooltip"
          data-title={`Související položka ${
            entry.timestamp.getFullYear() > props.year
              ? "už z následujícího"
              : "ještě z předešlého"
          } roku: ${formatDate(entry.timestamp, /*year=*/ true)}`}
        >
          {props.children}
        </div>
      );
    }
    return <>{props.children}</>;
  };

  return (
    <div key="entry">
      <InlineBlock className="w75px">
        <Note entry={entry} onUpdate={props.onUpdate} showValue={false} />
        <BlockFloatRight>
          <MaybeTooltip>{formatDate(entry.timestamp)}</MaybeTooltip>
        </BlockFloatRight>
      </InlineBlock>
      <BlockFloatRight className="w75px text-right">
        <MaybeTooltip>
          <div className={getClassNameByEntryType(entry.entryType)}>
            {formatCost(entry.amount)}
          </div>
        </MaybeTooltip>
      </BlockFloatRight>
    </div>
  );
};

const Group = (props) => {
  const group = props.group;
  const person = group.person;

  const [entries, setEntries] = useState(group.entries);

  // Description may be expensive, so we only render first when the row becomes
  // visible.
  const ref = useRef();
  const isVisible = useIsVisible(ref, { once: true });

  const onEntryUpdate = (newEntry) => {
    const newEntries = Object.assign([], entries);
    const i = newEntries.findIndex((entry) => entry.id === newEntry.id);
    newEntries[i] = { ...newEntries[i], ...newEntry };
    setEntries(newEntries);
  };

  const description = () => {
    const entry = entries[0];

    const sourceDescription = () => {
      switch (group.sourceType) {
        case "Registration":
          return (
            <Registration
              registration={entry.sourceRegistration}
              person={person}
              isVisible={isVisible}
            />
          );
        case "Event":
          return (
            <Event
              event={entry.sourceEvent}
              person={person}
              isVisible={isVisible}
            />
          );
        case "Payment":
          return <Payment entry={entry} />;
        case "Family":
        case "FamilyMembership":
          return "";
        case null:
          // Special entry (e.g., rebalance).
          return "";
        default:
          throw `Unknown source type ${group.sourceType}.`;
      }
    };

    return (
      <>
        <div>{sourceDescription()}</div>
        {entries.map((entry) => {
          if (!entry.note) return;
          return (
            <div key={entry.id}>
              <i
                className="fa fa-pencil"
                data-show="tooltip"
                data-title={formatNoteLabel(entry)}
              />{" "}
              <Note entry={entry} onUpdate={onEntryUpdate} showValue />
            </div>
          );
        })}
      </>
    );
  };

  const spec = getSpecFromGroup(group);

  return (
    <BalanceRow>
      <BlockFloatLeft className="balance-cell-header balance-cell-grey">
        <div>
          <Label bsStyle={spec.label}>{spec.desc}</Label>{" "}
          {props.onFlipZoom != null && (
            <BlockFloatRight>
              <a role="button" onClick={() => props.onFlipZoom()}>
                <i
                  className={`fa fa-search-${props.zoomed ? "minus" : "plus"}`}
                  data-show="tooltip"
                  data-title="Zobrazit jen související položky."
                />
              </a>
            </BlockFloatRight>
          )}
        </div>
        <div>
          <b>{person.firstName}</b>
        </div>
        <div>
          <b>{person.lastNameExtension}</b>
        </div>
        <div>{person.regNumber}</div>
      </BlockFloatLeft>
      <BlockFloatRight className="balance-cell-grey">
        {entries.map((entry) => (
          <Collapse key={entry.id} in={props.showZeroEntries || !!entry.amount}>
            <div>
              <Entry entry={entry} year={props.year} onUpdate={onEntryUpdate} />
            </div>
          </Collapse>
        ))}
      </BlockFloatRight>
      <div ref={ref}>{description()}</div>
    </BalanceRow>
  );
};

const BalanceEntryTable = (props) => {
  const url = props.familyId
    ? ["families", props.familyId, "balanceEntries"]
    : ["people", props.personId, "balanceEntries"];

  const entries = useQuery([
    url,
    {
      include: [
        "person",
        "sourceRegistration.registrationLevel",
        "sourceEvent",
        "sourcePayment",
        "sourceFamilyMembership",
      ],
      filter: {
        partially_in_year: props.year,
      },
      sort: "timestamp",
    },
  ]);

  const lastYearEntry = useQuery([
    url,
    {
      filter: {
        in_year: props.year - 1,
        limit: 1,
      },
      sort: "-timestamp",
    },
  ]);

  const [showBySpecId, setShowBySpecId] = useState({});
  const [showZeroEntries, setShowZeroEntries] = useState(true);
  const [zoomedSourceId, setZoomedSourceId] = useState(null);

  {
    const alerts = makeQueryErrorAlerts([entries, lastYearEntry]);
    if (alerts.length) {
      return alerts;
    }
  }

  if (entries.isLoading || lastYearEntry.isLoading) {
    return <i className="fa fa-spinner fa-spin" />;
  }

  let atLeastOneZeroEntry = false;
  const numGroupsBySourceId = {};

  // Split entries into groups and sections. Balance entries < groups <
  // sections:
  // * section I
  //   * group A (e.g., event)
  //     * entry
  //     * entry
  //   * group B (e.g., registration)
  //     * entry
  // * section II (positive payment)
  //   * group (payment to cover for events)
  //     * entry
  //   * group (payment to cover for registration)
  //     * entry
  //
  // Sections separate groups of 3 types (see `SectionType`):
  // * Positive payments (POSITIVE_PAYMENT).
  // * Rebalances (REBALANCE).
  // * Everything else (DEFAULT).
  //
  // There is a balance overview in between every 2 sections.
  let sections = [];
  let groups = {};
  sections.push(groups);

  entries.data.forEach((entry, index, array) => {
    atLeastOneZeroEntry ||= entry.amount === 0;

    if (index > 0) {
      const prevEntry = array[index - 1];
      if (
        // Insert a section break if on year slips...
        entry.timestamp.getFullYear() !== prevEntry.timestamp.getFullYear() ||
        // ...or between different section types (positive payments, rebalances,
        // etc.), but only in the current year.
        (entry.timestamp.getFullYear() === props.year &&
          getEntrySectionType(entry) !== getEntrySectionType(prevEntry))
      ) {
        groups = {};
        sections.push(groups);
      }
    }

    const key = [entry.sourceType, entry.sourceId, entry.person.id];
    let existing = groups[key];
    if (existing === undefined) {
      if (entry.sourceId) {
        if (!(entry.sourceId in numGroupsBySourceId)) {
          numGroupsBySourceId[entry.sourceId] = 1;
        } else {
          numGroupsBySourceId[entry.sourceId]++;
        }
      }

      existing = {
        key: key,
        sourceType: entry.sourceType,
        sourceId: entry.sourceId,
        person: entry.person,
        entries: [],
      };
      groups[key] = existing;
    }
    existing.entries.push(entry);
  });

  // Sort groups in sections by the time of the *last* balance entry.
  sections = sections.map((groups) =>
    Object.values(groups).sort((a, b) => {
      a = a.entries[a.entries.length - 1].timestamp;
      b = b.entries[b.entries.length - 1].timestamp;
      if (a < b) return -1;
      if (b < a) return 1;
      return 0;
    })
  );

  const seenSpecs = {};
  let rows = [];
  let lastRowIsSkipped = false;
  sections.forEach((section, sectionIndex) => {
    if (section.length === 0) return;

    // Beginning of year balance.
    if (
      section[0].entries[0].timestamp.getFullYear() === props.year &&
      (sectionIndex === 0 ||
        sections[sectionIndex - 1][0].entries[0].timestamp.getFullYear() <
          props.year)
    ) {
      const entry =
        lastYearEntry.data.length === 1
          ? lastYearEntry.data[0]
          : {
              id: 0,
              balanceRegistration: 0,
              balanceEvent: 0,
              balanceStuff: 0,
            };

      rows.push(
        <BalanceRow key={`lastYear-${entry.id}`}>
          <Summary
            label={`Bilance na začátku roku ${props.year}`}
            entry={entry}
          />
        </BalanceRow>
      );
    }

    section.forEach((group) => {
      const spec = getSpecFromGroup(group);
      seenSpecs[spec.id] = spec;

      const filtered = showBySpecId[spec.id] === false;
      const wrongYear = group.entries[0].timestamp.getFullYear() !== props.year;
      const zoomed = !!zoomedSourceId && zoomedSourceId === group.sourceId;
      const unzoomed = !!zoomedSourceId && zoomedSourceId !== group.sourceId;
      const allEntriesZero =
        group.entries.findIndex((entry) => !!entry.amount) == -1;
      const show =
        !filtered && !unzoomed && (showZeroEntries || !allEntriesZero);
      const hide = wrongYear && !zoomed;
      rows.push(
        <Collapse key={`${sectionIndex}-${group.key}`} in={show && !hide}>
          <div>
            <Group
              group={group}
              year={props.year}
              unzoomed={unzoomed}
              zoomed={zoomed}
              showZeroEntries={showZeroEntries}
              onFlipZoom={
                numGroupsBySourceId[group.sourceId] > 1
                  ? () =>
                      setZoomedSourceId(zoomedSourceId ? null : group.sourceId)
                  : null
              }
            />
          </div>
        </Collapse>
      );

      if (!show && !hide) {
        if (!lastRowIsSkipped) {
          rows.push(
            <BalanceRow key={`${sectionIndex}-${group.key}-unzoom`}>
              <a
                role="button"
                onClick={() => {
                  setShowBySpecId({});
                  setZoomedSourceId(null);
                  setShowZeroEntries(true);
                }}
              >
                <div className="text-center unzoom">
                  <Badge>Zobrazit vše</Badge>
                </div>
              </a>
            </BalanceRow>
          );
        }
        lastRowIsSkipped = true;
      } else {
        lastRowIsSkipped = false;
      }
    });

    const lastGroup = section[section.length - 1];
    const lastEntry = lastGroup.entries[lastGroup.entries.length - 1];

    if (!lastRowIsSkipped && lastEntry.timestamp.getFullYear() === props.year) {
      rows.push(
        <Collapse key={`${sectionIndex}-break`} in={!zoomedSourceId}>
          <div>
            <BalanceRow>
              <Summary
                label={`Stav k ${formatDate(
                  lastEntry.timestamp,
                  /*year=*/ true
                )}`}
                entry={lastEntry}
              />
            </BalanceRow>
          </div>
        </Collapse>
      );
    }
  });

  return (
    <>
      <ButtonToolbar>
        <ButtonGroup>
          {Object.keys(seenSpecs)
            .sort()
            .map((specId) => {
              const spec = seenSpecs[specId];
              const active =
                spec.id in showBySpecId ? showBySpecId[spec.id] : true;
              return (
                <Button
                  key={spec.label}
                  active={active}
                  onClick={() =>
                    setShowBySpecId({
                      ...showBySpecId,
                      ...{
                        [spec.id]: !active,
                      },
                    })
                  }
                >
                  <span className={`text-${spec.label}`}>{spec.desc}</span>
                </Button>
              );
            })}
        </ButtonGroup>
        <Button
          value="showZeroEntries"
          disabled={!atLeastOneZeroEntry}
          className="pull-right"
          active={!showZeroEntries}
          onClick={() => setShowZeroEntries(!showZeroEntries)}
        >
          <i className="fa fa-eye-slash" /> Skrýt nulové
        </Button>
      </ButtonToolbar>
      <p />
      {rows}
      <RestoreScrolling />
    </>
  );
};

BalanceEntryTable.propTypes = {
  year: PropTypes.number.isRequired,
};

export default BalanceEntryTable;
