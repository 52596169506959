import { useMutation, useQuery } from "jsonapi-react";
import Alert from "react-bootstrap/lib/Alert";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import CarReportForm from "./CarReportForm";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Panel from "react-bootstrap/lib/Panel";
import Row from "react-bootstrap/lib/Row";
import { useReactToPrint } from "react-to-print";
import {
  makeQueryErrorAlerts,
  makeValidationErrorAlerts,
} from "../util/Errors";

const formatCost = (value) => {
  return value.toFixed(value % 1 === 0 ? 0 : 2);
};

const CarReport = (props) => {
  const printableRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `car-report-${props.id}`,
    content: () => printableRef.current,
  });
  const carReport = useQuery([
    "carReports",
    props.id,
    {
      include: [
        "car",
        "directPerson",
        "event",
        "person",
        "people",
        "transportOffer.person",
        "transportOffer.people",
        "transportOffer.transportOfferJoins.person",
      ],
    },
  ]);
  const [mutate, mutation] = useMutation(["carReports", props.id]);
  const [deleteError, setDeleteError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  if (carReport.isLoading) {
    return (
      <i
        className="fa fa-spinner fa-spin"
        data-show="tooltip"
        data-title="Načítá se..."
      />
    );
  }

  {
    const errors = makeQueryErrorAlerts([carReport]);
    if (errors.length) return errors;
  }

  const eventRow = () => {
    const event = carReport.data.event;
    if (!event) {
      return (
        <Row key="noEvent">
          <Col xs={2}>
            <i className="fa fa-location-arrow" />
          </Col>
          <Col xs={10}>Bez příslušnosti k závodu</Col>
        </Row>
      );
    }

    const noneventReason = () => {
      const value = event.noneventReason;
      if (!value) return <></>;
      return (
        <Row key="noneventReason">
          <Col xs={2}>
            <i className="fa fa-question" />
          </Col>
          <Col xs={10}>{value}</Col>
        </Row>
      );
    };

    return (
      <>
        <Row>
          <Col xs={2}>
            <i className="fa fa-location-arrow" />
          </Col>
          <Col xs={10}>
            <a href={event.linkSelf}>{event.title}</a>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <i className="fa fa-map-marker" />
          </Col>
          <Col xs={10}>{event.place}</Col>
        </Row>
        {noneventReason()}
      </>
    );
  };

  const noteRow = () => {
    const value = carReport.data.note;
    if (!value) return <></>;
    return (
      <Row key="note">
        <Col xs={2}>
          <i className="fa fa-comment" />
        </Col>
        <Col xs={10}>{value}</Col>
      </Row>
    );
  };

  const car = () => {
    const car = carReport.data.car;
    if (!car) return <></>;

    return (
      <>
        <Row key="carTitle">
          <Col xs={2}>
            <i className="fa fa-car" />
          </Col>
          <Col xs={10}>
            <a href={car.linkSelf}>{car.title}</a>
          </Col>
        </Row>
        <Row key="carType">
          <Col xsOffset={2} xs={10}>
            {car.carType}
          </Col>
        </Row>
        <Row key="spz">
          <Col xsOffset={2} xs={10}>
            {car.spz}
          </Col>
        </Row>
      </>
    );
  };

  const formatDate = (date) =>
    `${date.getDate()}.\u00a0${
      date.getMonth() + 1
    }.\u00a0${date.getFullYear()}`;

  const numPassengersRows = () => {
    const maybeReturn = () => {
      if (
        carReport.data.numPassengersOutward ===
        carReport.data.numPassengersReturn
      )
        return <></>;

      return (
        <>
          {" "}
          tam, <b>{carReport.data.numPassengersReturn}</b> zpět
        </>
      );
    };

    return (
      <Row>
        <Col xs={7}>Cestujících (vč. řidiče)</Col>
        <Col xs={5}>
          <b>{carReport.data.numPassengersOutward}</b>
          {maybeReturn()}
        </Col>
      </Row>
    );
  };

  const carFullnessRows = () => {
    const car = carReport.data.car;
    if (!car) return <></>;

    const capacity = car.defaultPlaces + 1;
    const fullnessOutward = carReport.data.numPassengersOutward / capacity;
    const fullnessReturn = carReport.data.numPassengersReturn / capacity;
    const format = (fullness) => {
      return `${Math.round(100 * fullness)}`;
    };

    const maybeReturn = () => {
      if (fullnessOutward === fullnessReturn) return <></>;

      return (
        <>
          {" "}
          tam, <b>{format(fullnessReturn)}</b>% zpět
        </>
      );
    };

    const maybeWarning = () => {
      if (fullnessOutward <= 1 && fullnessReturn <= 1) return <></>;
      return (
        <span className="pull-right">
          <i
            className="fa fa-exclamation-triangle hidden-print"
            data-show="tooltip"
            data-title="Tolik lidí se do auta nevejde!"
          />
        </span>
      );
    };

    return (
      <Row>
        <Col xs={7}>Zaplněnost</Col>
        <Col xs={5}>
          <b>{format(fullnessOutward)}</b>%{maybeReturn()} (kapacita:&nbsp;
          {capacity}){maybeWarning()}
        </Col>
      </Row>
    );
  };

  const coverageRows = () => {
    const maybeWarning = () => {
      if (carReport.data.quotient === null) return <></>;
      return (
        <i
          className="fa fa-exclamation-triangle hidden-print"
          data-show="tooltip"
          data-title="Administrátor nastavil hodnotu pro tento cesťák ručně."
        />
      );
    };

    return (
      <Row>
        <Col xs={7}>
          Příspěvěk oddílu{" "}
          <i
            className="fa fa-question-circle hidden-print"
            data-show="tooltip"
            data-title="Když je auto zaplněné alespoň na 80%, cestujícím s plnými příspěvky hradí oddíl 100%. Čím menší zaplněnost, tím měnší příspěvek."
          />
        </Col>
        <Col xs={5}>
          <b>{formatCost(carReport.data.quotientComputed)}</b>% {maybeWarning()}
        </Col>
      </Row>
    );
  };

  const mileageRows = () => {
    const car = carReport.data.car;
    if (!car) return <></>;

    return (
      <Row key="mileage">
        <Col xs={7}>Spotřeba</Col>
        <Col xs={5}>
          <b>{car.mileage.toFixed(1)}</b>&nbsp;l/100&nbsp;km
        </Col>
      </Row>
    );
  };

  const costOrDash = (value) => {
    if (value === 0) return <>&mdash;</>;
    return (
      <>
        <b>{formatCost(value)}</b>&nbsp;Kč
      </>
    );
  };

  let paid = 0.0;
  let breakdownRows = [];
  const allPassengers = [
    carReport.data.directPerson || carReport.data.person,
  ].concat(carReport.data.people);
  carReport.data.passengerBreakdowns.forEach((passenger) => {
    const id = passenger.person;
    const person = allPassengers.find((passenger) => passenger.id === id);
    paid += passenger.paid;

    const personCol = () => {
      if (!person) {
        return <>Neznámý cestující</>;
      }
      const maybeSkipped = () => {
        if (!passenger.skipped_race) return <></>;
        return (
          <i
            className="fa fa-exclamation-circle hidden-print"
            data-show="tooltip"
            data-title="Nebežel, nebo alespoň ne v závodní kategorii."
          />
        );
      };
      return (
        <>
          <a href={person.linkSelf}>
            {person.name}
            <span className="pull-right hidden-print">
              [{person.regNumber}]
            </span>
          </a>{" "}
          {maybeSkipped()}
        </>
      );
    };

    breakdownRows.push(
      <Row key={id ? id : `unknown-${breakdownRows.length}`}>
        <Col sm={3}>{personCol()}</Col>
        <Col sm={6}>
          <Row>
            <Col xs={3}>
              <div className="pull-right">
                <b>{formatCost(passenger.share)}</b>&nbsp;Kč{" "}
                {passenger.outward === true ? (
                  <i
                    className="fa fa-arrow-right"
                    data-show="tooltip"
                    data-title="Jen tam."
                  />
                ) : passenger.outward === false ? (
                  <i
                    className="fa fa-arrow-left"
                    data-show="toolti"
                    data-title="Jen zpět."
                  />
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={3}>
              <div className="pull-right">{costOrDash(passenger.covered)}</div>
            </Col>
            <Col xs={3}>
              <div className="pull-right">{costOrDash(passenger.charged)}</div>
            </Col>
            <Col xs={3}>
              <div className="pull-right">{costOrDash(passenger.paid)}</div>
            </Col>
          </Row>
        </Col>
        <Col sm={3}>{passenger.msg}</Col>
      </Row>
    );
  });

  const errorAlerts = () => {
    let result = makeValidationErrorAlerts([mutation]);
    if (deleteError) {
      result.push(
        <Alert key="deleteError" bsStyle="danger">
          {deleteError.title}
        </Alert>
      );
    }
    return result;
  };

  const approveButton = () => {
    if (!carReport.data.permissions.approvable) return <></>;
    return (
      <Button
        key="approveButton"
        bsStyle={carReport.data.approved ? "warning" : "success"}
        disabled={mutation.isLoading}
        onClick={() => mutate({ approved: !carReport.data.approved })}
      >
        {carReport.data.approved ? "Odeschválit" : "Schválit"}
      </Button>
    );
  };

  const updateButton = () => {
    if (!carReport.data.permissions.editable) return <></>;
    return (
      <Button
        key="updateButton"
        bsStyle="primary"
        onClick={() => setShowEditModal(true)}
      >
        Upravit
      </Button>
    );
  };

  const deleteButton = () => {
    if (!carReport.data.permissions.deletable) return <></>;
    return (
      <Button
        key="deleteButton"
        bsStyle="danger"
        onClick={async () => {
          if (!(await confirm("Opravdu smazat?"))) {
            return;
          }

          const { error } = await mutation.client.delete([
            "carReports",
            props.id,
          ]);

          if (error) {
            setDeleteError(error);
          } else {
            window.location.href = "/car_reports";
          }
        }}
      >
        Smazat
      </Button>
    );
  };

  return (
    <>
      <div ref={printableRef}>
        <h1>Cestovní výkaz</h1>
        {carReport.data.approved || (
          <Alert key="approvedWarning" bsStyle="warning">
            Čeká na schválení.
          </Alert>
        )}
        {errorAlerts()}
        <Row>
          <Col sm={6}>
            <Panel>
              <Panel.Body>
                {eventRow()}
                <Row>
                  <Col xs={2}>
                    <i className="fa fa-calendar" />
                  </Col>
                  <Col xs={10}>{formatDate(carReport.data.date)}</Col>
                </Row>
                {car()}
                {noteRow()}
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Body>
                {numPassengersRows()}
                {carFullnessRows()}
                {coverageRows()}
              </Panel.Body>
            </Panel>
          </Col>
          <Col sm={6}>
            <Panel>
              <Panel.Body>
                <Row>
                  <Col xs={7}>Vzdálenost (tam + zpět)</Col>
                  <Col xs={5}>
                    <b>{carReport.data.distance.toFixed()}</b>&nbsp;km
                  </Col>
                </Row>
                {mileageRows()}
                <Row>
                  <Col xs={7}>Cena PHM</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.fuelPrice)}</b>&nbsp;Kč/l
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>Pevná sazba za km</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.coverage)}</b>&nbsp;Kč/km
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={7}>Náklad za vzdálenost</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.feeFixed || 0)}</b>&nbsp;Kč
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>Náklad za PHM</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.feeFuel || 0)}</b>&nbsp;Kč
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>Náklad za parkovné</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.feeParking || 0)}</b>&nbsp;Kč
                  </Col>
                </Row>
              </Panel.Body>
              <Panel.Footer>
                <Row>
                  <Col xs={7}>Náklady celkem</Col>
                  <Col xs={5}>
                    <b>{formatCost(carReport.data.totalCost)}</b>&nbsp;Kč
                  </Col>
                </Row>
              </Panel.Footer>
            </Panel>
          </Col>
        </Row>
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={3}>Cestující</Col>
              <Col sm={6}>
                <Row>
                  <Col xs={3}>
                    <div className="pull-right">Podíl</div>
                  </Col>
                  <Col xs={3}>
                    <div className="pull-right">
                      Příspěvek{" "}
                      <i
                        className="fa fa-question-circle hidden-print"
                        data-show="tooltip"
                        data-title="Těm s plnými příspěvky oddíl přispěje podle zaplněnosti. Viz Příspěvěk oddílu."
                      />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="pull-right">Olin strhne</div>
                  </Col>
                  <Col xs={3}>
                    <div className="pull-right">Řidič dostane</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>{breakdownRows}</Panel.Body>
          <Panel.Footer>
            <Row>
              <Col sm={3}>Řidiči k proplacení</Col>
              <Col sm={6}>
                <Row>
                  <Col xsOffset={9} xs={3}>
                    <div className="pull-right">
                      <b>{formatCost(paid)}</b>&nbsp;Kč
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>Bankovní účet</Col>
              <Col sm={6}>
                <Row>
                  <Col xsOffset={9} xs={3}>
                    <div className="pull-right">
                      <b>{carReport.data.bankAccount}</b>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel.Footer>
        </Panel>
        <div className="visible-print-block" style={{ marginTop: "1cm" }}>
          <Row>
            <Col sm={6}>Datum:</Col>
            <Col sm={6}>Podpis:</Col>
          </Row>
          <hr style={{ marginTop: "1cm" }} />
        </div>
        <div className="hidden-print">
          {deleteButton()}
          <ButtonGroup className="pull-right">
            {approveButton()}
            <Button
              bsStyle="default"
              disabled={!carReport.data.approved}
              onClick={() => handlePrint()}
            >
              <i className="fa fa-print" /> Vytisknout
            </Button>
            {updateButton()}
          </ButtonGroup>
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Upravit cesťák</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CarReportForm
                carReport={carReport.data}
                onClose={() => setShowEditModal(false)}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

CarReport.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CarReport;
