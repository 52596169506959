import Editable from "./Editable";
import FormControl from "react-bootstrap/lib/FormControl";
import PropTypes from "prop-types";
import React from "react";

const InputComponent = (props) => {
  return (
    <FormControl
      style={{ width: 290 }}
      type="text"
      value={props.currentValue || ""}
      onChange={(e) => {
        props.setPendingValue(e.target.value);
      }}
    />
  );
};

InputComponent.propTypes = {
  currentValue: PropTypes.string,
  setPendingValue: PropTypes.func,
};

const EditableText = (props) => {
  return <Editable inputComponent={InputComponent} {...props} />;
};

export default EditableText;
