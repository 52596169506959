import Alert from "react-bootstrap/lib/Alert";
import React from "react";

const formatError = (error, key) => {
  return (
    <Alert key={key} bsStyle="danger">
      <p>{error.detail || error.title}</p>
      {error.meta?.exception && <p>{error.meta.exception}</p>}
    </Alert>
  );
};

export function makeQueryErrorAlerts(queries) {
  let alerts = [];
  queries.forEach((query, index) => {
    if (query.error) {
      alerts.push(formatError(query.error, `queryError-${index}`));
    }
  });
  return alerts;
}

export function makeValidationErrorAlerts(mutations) {
  let alerts = [];
  mutations.forEach((mutation, mutationIndex) => {
    if (mutation.error) {
      alerts.push(
        formatError(mutation.error, `mutationError-${mutationIndex}`)
      );
    }

    if (!mutation.errors) return;
    mutation.errors.forEach((error, errorIndex) => {
      if (error) {
        alerts.push(
          formatError(error, `mutationErrors-${mutationIndex}-${errorIndex}`)
        );
      }
    });
  });
  return alerts;
}
