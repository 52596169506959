import { useMutation } from "jsonapi-react";
import Alert from "react-bootstrap/lib/Alert";
import Badge from "react-bootstrap/lib/Badge";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Collapse from "react-bootstrap/lib/Collapse";
import Constants from "../util/Constants";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Form from "react-bootstrap/lib/Form";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Modal from "react-bootstrap/lib/Modal";
import PropTypes from "prop-types";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import Select from "react-select";
import { makeValidationErrorAlerts } from "../util/Errors";

const personToOption = (person) => {
  return {
    value: person.id,
    label: `${person.name} (${person.regNumber})`,
    person: person,
  };
};

const Label = (props) => {
  return (
    <Col key="label" sm={2} componentClass={ControlLabel} {...props}>
      {props.children}
    </Col>
  );
};

const Value = (props) => {
  return (
    <Col key="value" sm={10} {...props}>
      {props.children}
    </Col>
  );
};

const PeopleSelect = (props) => {
  const peopleOptions = props.people.map((person) => personToOption(person));
  const defaultValue = (() => {
    if (!props.defaultPerson) return null;
    const person = props.people.find(
      (person) => person.id === props.defaultPerson.id
    );
    if (!person) return null;
    return personToOption(person);
  })();

  return (
    <Select
      options={peopleOptions}
      defaultValue={defaultValue}
      isClearable={true}
      onChange={(value) => props.onChange(value?.person)}
      placeholder=""
    />
  );
};

const PeopleMultiSelect = (props) => {
  const peopleOptions = props.people.map((person) => personToOption(person));

  // We use reducer mostly to prevent a race between `onChange` and `onInputChange`.
  const [state, dispatch] = useReducer((state, action) => action(state), {
    selectedPeople: [],
    inputValue: "",
  });

  useEffect(
    () => props.onChange(state.selectedPeople),
    [String(state.selectedPeople)]
  );

  return (
    <Select
      isMulti
      options={peopleOptions}
      value={state.selectedPeople}
      isClearable={true}
      onChange={(values) => {
        dispatch((state) => ({
          selectedPeople: values,
          inputValue: state.inputValue,
        }));
      }}
      inputValue={state.inputValue}
      onInputChange={(value) => {
        dispatch((state) => {
          const foundPeople = [];
          const notFoundParts = [];

          value.split(",").forEach((regNumber) => {
            regNumber = regNumber.trim();

            const found = props.people.find(
              (person) =>
                person.regNumber.toUpperCase() === regNumber.toUpperCase()
            );

            if (found) {
              foundPeople.push(personToOption(found));
            } else {
              notFoundParts.push(regNumber);
            }
          });

          if (foundPeople.length === 0 && notFoundParts.length === 0) {
            return state;
          }

          return {
            // Only keep unique people.
            selectedPeople: [...state.selectedPeople, ...foundPeople].filter(
              (person, index, array) => array.indexOf(person) === index
            ),
            inputValue: notFoundParts.join(","),
          };
        });
      }}
      placeholder=""
    />
  );
};

const eventToOption = (event, suffix = "") => {
  if (!event) return null;
  return {
    value: event.id,
    label: event.name + suffix,
    event: event,
  };
};

const PaymentModalInner = (props) => {
  const constants = useContext(Constants);
  const newPayment = !props.payment;
  const [attr, setAttr] = useState(newPayment ? {} : props.payment);

  const [mutate, mutation] = useMutation([
    "payments",
    ...(newPayment ? [] : [props.payment.id]),
  ]);

  const isPersonSelected = newPayment ? attr.people?.length > 0 : !!attr.person;
  const isEventSelected = !!attr.event;

  const errors = (() => {
    let errors = {};
    if (!attr.amount) errors.amount = "Částka musí být vyplněna.";
    return errors;
  })();

  const targetsOptions = useMemo(
    () =>
      Object.entries(constants.paymentTargets).map(([value, label]) => {
        return { value: value, label: label };
      }),
    []
  );

  const ourEventsOptions = useMemo(() => {
    const byYear = {};
    props.ourEvents.forEach((event) => {
      const year = event.date.getFullYear();
      if (!(year in byYear))
        byYear[year] = {
          label: year,
          options: [],
        };
      byYear[year].options.push(eventToOption(event));
    });
    return Object.values(byYear).reverse();
  }, []);

  const bankRow = () => {
    if (attr.bank) {
      return (
        <>
          <FormGroup key="payee">
            <Label>Účet</Label>
            <Value>
              <FormControl.Static>
                <code>{attr.payee}</code>
              </FormControl.Static>
            </Value>
          </FormGroup>
          <FormGroup key="vs">
            <Label>VS</Label>
            <Value>
              <FormControl.Static>
                <code>{attr.variableSymbol ? attr.variableSymbol : "---"}</code>
              </FormControl.Static>
            </Value>
          </FormGroup>
        </>
      );
    } else {
      return (
        <FormGroup key="pseudopayment">
          <Label />
          <Value>
            <Badge>Pseudoplatba</Badge>
          </Value>
        </FormGroup>
      );
    }
  };

  const amount = () => {
    const field = () => {
      if (newPayment || !attr.bank) {
        return (
          <FormControl
            autoFocus
            type="number"
            value={attr.amount || ""}
            onChange={(e) =>
              setAttr(Object.assign({}, attr, { amount: e.target.value }))
            }
          />
        );
      } else {
        return <FormControl.Static>{attr.amount}</FormControl.Static>;
      }
    };

    return (
      <FormGroup key="amount" validationState={errors.amount && "error"}>
        <Label>Částka</Label>
        <Value>
          {field()}
          <HelpBlock>{errors.amount}</HelpBlock>
        </Value>
      </FormGroup>
    );
  };

  const noteRow = () => {
    return (
      <FormGroup key="note">
        <Label>Pozn.</Label>
        <Value>
          <FormControl
            type="text"
            value={attr.note || ""}
            onChange={(e) =>
              setAttr(Object.assign({}, attr, { note: e.target.value }))
            }
          />
        </Value>
      </FormGroup>
    );
  };

  const personRow = () => {
    const select = () => {
      if (newPayment) {
        return (
          <>
            <PeopleMultiSelect
              people={props.people}
              onChange={(people) => {
                setAttr(
                  Object.assign({}, attr, {
                    people: people,
                  })
                );
              }}
            />
            <HelpBlock>
              Pro hromadnou platbu můžeš vložit registračky oddělené čárkou
              (DKP1111, DKP2222, ...)
            </HelpBlock>
          </>
        );
      }

      return (
        <PeopleSelect
          people={props.people}
          defaultPerson={attr.person}
          onChange={(person) =>
            setAttr(Object.assign({}, attr, { person: person }))
          }
        />
      );
    };

    return (
      <Collapse in={isPersonSelected || !isEventSelected}>
        <FormGroup key="person">
          <Label>{newPayment ? "Členové DKP" : "Člen DKP"}</Label>
          <Value>{select()}</Value>
        </FormGroup>
      </Collapse>
    );
  };

  const targetRow = () => {
    const value = targetsOptions.find((option) => option.value == attr.target);

    return (
      <Collapse in={isPersonSelected}>
        <FormGroup key="target">
          <Label>Účel</Label>
          <Value>
            <Select
              options={targetsOptions}
              defaultValue={value}
              isClearable={true}
              onChange={(value) =>
                setAttr(
                  Object.assign({}, attr, { target: value?.value || null })
                )
              }
              placeholder=""
            />
          </Value>
        </FormGroup>
      </Collapse>
    );
  };

  const eventRow = () => {
    const getOptionFromSession = () => {
      const event = props.ourEvents.find(
        (event) => event.id == sessionStorage.paymentModalEventId
      );
      if (!event) return null;
      const option = eventToOption(event);
      option.label = (
        <span style={{ opacity: 0.5 }}>Naposled vybráno: {option.label}</span>
      );
      return option;
    };

    return (
      <Collapse in={isEventSelected || !isPersonSelected}>
        <FormGroup key="event">
          <Label>Závod</Label>
          <Value>
            <Select
              options={ourEventsOptions}
              defaultValue={
                eventToOption(attr.event) ||
                // Suggest the last selected event.
                getOptionFromSession()
              }
              isClearable={true}
              onChange={(value) =>
                setAttr(
                  Object.assign({}, attr, { event: value?.event || null })
                )
              }
              placeholder=""
            />
          </Value>
        </FormGroup>
      </Collapse>
    );
  };

  const eventClubRow = () => {
    return (
      <Collapse in={isEventSelected}>
        <FormGroup key="eventClub">
          <Label>Klub</Label>
          <Value>
            <FormControl
              type="text"
              value={attr.eventClub || ""}
              onChange={(e) =>
                setAttr(Object.assign({}, attr, { eventClub: e.target.value }))
              }
            />
          </Value>
        </FormGroup>
      </Collapse>
    );
  };

  const eventRegNumberRow = () => {
    return (
      <Collapse in={isEventSelected}>
        <FormGroup key="eventRegNumber">
          <Label>Závodník</Label>
          <Value>
            <FormControl
              type="text"
              value={attr.eventRegNumber || ""}
              onChange={(e) =>
                setAttr(
                  Object.assign({}, attr, { eventRegNumber: e.target.value })
                )
              }
            />
          </Value>
        </FormGroup>
      </Collapse>
    );
  };

  const destroy = () => {
    if (newPayment || attr.bank) return;
    return (
      <Button
        className="pull-left"
        bsStyle="danger"
        disabled={mutation.isLoading}
        onClick={async () => {
          await mutation.client.delete(["payments", props.payment.id]);
          props.onClose();
        }}
      >
        Smazat
      </Button>
    );
  };

  return (
    <>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>Platba</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {makeValidationErrorAlerts([mutation])}
          <Form horizontal>
            {bankRow()}
            {amount()}
            {noteRow()}
            {personRow()}
            {targetRow()}
            {eventRow()}
            {eventClubRow()}
            {eventRegNumberRow()}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {destroy()}
          <Button
            onClick={() => {
              // Discard edits.
              setAttr(newPayment ? {} : props.payment);
              props.onClose();
            }}
          >
            Zrušit
          </Button>
          <Button
            bsStyle="primary"
            disabled={mutation.isLoading || Object.keys(errors).length > 0}
            onClick={async () => {
              if (attr.people?.length > 0) {
                let copy = Object.assign({}, attr);
                delete copy.people;
                for (let person in attr.people) {
                  copy.person = attr.people[person].person;
                  const response = await mutate(copy);
                  if (response.error) {
                    return;
                  }
                }
              } else {
                const copy = Object.assign({}, attr);
                delete copy.people;
                copy.person ||= {};
                copy.event ||= {};
                const response = await mutate(copy);
                if (response.error) {
                  return;
                }
              }

              // Store the last selected event for future convenience.
              if (attr.event && props.payment?.event?.id !== attr.event.id) {
                sessionStorage.setItem("paymentModalEventId", attr.event.id);
              }

              props.onClose();
            }}
          >
            {newPayment ? "Vytvořit" : "Upravit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PaymentModal = (props) => {
  // Mount on the inner modal on first show.
  const [show, setShow] = useState(props.show);
  if (!show && !props.show) return <></>;
  if (!show && props.show) {
    setShow(true);
    return <></>;
  }
  return <PaymentModalInner show={props.show} {...props} />;
};

PaymentModal.propTypes = {
  // Leave missing for new payments.
  payment: PropTypes.object,
  show: PropTypes.bool.isRequired,
  ourEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func,
};

export default PaymentModal;
