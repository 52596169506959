import { useMutation, useQuery } from "jsonapi-react";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Button from "react-bootstrap/lib/Button";
import Select from "react-select";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Col from "react-bootstrap/lib/Col";
import Form from "react-bootstrap/lib/Form";
import FormControl from "react-bootstrap/lib/FormControl";
import InputGroup from "react-bootstrap/lib/InputGroup";
import FormGroup from "react-bootstrap/lib/FormGroup";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Row from "react-bootstrap/lib/Row";
import {
  makeQueryErrorAlerts,
  makeValidationErrorAlerts,
} from "../util/Errors";

const formatCost = (value) => {
  return value.toFixed(value % 1 === 0 ? 0 : 2);
};

const Label = (props) => {
  return (
    <Col
      key="label"
      sm={props.size || 4}
      componentClass={ControlLabel}
      {...props}
    >
      {props.children}
    </Col>
  );
};

const Value = (props) => {
  return (
    <Col key="value" sm={props.size || 8} {...props}>
      {props.children}
    </Col>
  );
};

const CarReportForm = (props) => {
  const [attr, setAttr] = useState({
    id: props.carReport.id,
    // New entries may be linked to an event.
    ...(props.carReport.id || props.carReport),
  });

  const getAttr = (key) => {
    if (key in attr) return attr[key];
    return props.carReport[key];
  };

  const cars = useQuery(["cars", { include: ["person"] }]);

  const year = (() => {
    const noneventDate = getAttr("noneventDate");
    if (noneventDate) return noneventDate.getFullYear();
    if (getAttr("event")?.date) return getAttr("event").date.getFullYear();
    return null;
  })();

  const season = useQuery(
    year && [
      "seasons",
      {
        filter: {
          year: year,
        },
      },
    ]
  ).data?.at(0);

  const [mutate, mutation] = useMutation([
    "carReports",
    ...(attr.id ? [attr.id] : []),
  ]);

  const defaultFuelCost = (() => {
    if (!season) return null;
    if (!getAttr("car")) return null;
    return Number(
      getAttr("car").diesel ? season.carFuelDiesel : season.carFuelGas
    );
  })();

  let numTransportOfferJoinsOutward = 0;
  let numTransportOfferJoinsReturn = 0;
  (props.carReport.transportOffer?.transportOfferJoins || []).forEach((join) => {
    if (join.direction === "both") {
      ++numTransportOfferJoinsOutward;
      ++numTransportOfferJoinsReturn;
    } else if (join.direction === "outward") {
      ++numTransportOfferJoinsOutward;
    } else if (join.direction === "return") {
      ++numTransportOfferJoinsReturn;
    }
  });

  // Prefills.
  if (!("fuelPrice" in attr)) {
    if (props.carReport.fuelPrice) {
      attr.fuelPrice = props.carReport.fuelPrice;
    } else if (defaultFuelCost) {
      attr.fuelPrice = defaultFuelCost;
    }
  }

  if (!("numPassengersOutward" in attr)) {
    if (props.carReport.numPassengersOutward) {
      attr.numPassengersOutward = props.carReport.numPassengersOutward;
    } else if (props.carReport.transportOffer) {
      attr.numPassengersOutward = numTransportOfferJoinsOutward + 1;
    }
  }
  if (!("numPassengersReturn" in attr)) {
    if (props.carReport.numPassengersReturn) {
      attr.numPassengersReturn = props.carReport.numPassengersReturn;
    } else if (props.carReport.transportOffer) {
      attr.numPassengersReturn = numTransportOfferJoinsReturn + 1;
    }
  }

  const errors = (() => {
    let errors = {};
    if (!getAttr("event")?.date && !getAttr("noneventDate"))
      errors.noneventDate = "Vyber datum.";
    if (!getAttr("car")) errors.car = "Vyber auto.";
    if (!(getAttr("distance") > 0))
      errors.distance = "Vzdálenost musí být kladná.";
    if (!(getAttr("fuelPrice") > 0)) errors.fuelPrice = "Cena musí být kladná.";
    if (getAttr("feeParking") < 0)
      errors.feeParking = "Cena nesmí být záporná.";

    if (getAttr("transportOffer")) {
      const error = "Nemůže být méně než přihlášených.";
      if (getAttr("numPassengersOutward") < numTransportOfferJoinsOutward + 1) {
        errors.numPassengersOutward = error;
      }
      if (getAttr("numPassengersReturn") < numTransportOfferJoinsReturn + 1) {
        errors.numPassengersReturn = error;
      }
    } else {
      const error = "Počet cestujících musí být kladný.";
      if (!(getAttr("numPassengersOutward") > 0)) {
        errors.numPassengersOutward = error;
      }
      if (!(getAttr("numPassengersReturn") > 0)) {
        errors.numPassengersReturn = error;
      }
    }
    return errors;
  })();

  // Rows.
  const eventRow = () => {
    return (
      <FormGroup key="event">
        <Label>Závod</Label>
        <Value>
          <FormControl.Static>
            {getAttr("event") ? (
              <a href={getAttr("event").linkSelf}>{getAttr("event").name}</a>
            ) : (
              <>Bez příslušnosti k závodu</>
            )}
          </FormControl.Static>
        </Value>
      </FormGroup>
    );
  };

  const dateRow = () => {
    return (
      <FormGroup key="date" validationState={errors.noneventDate && "error"}>
        <Label>Datum</Label>
        <Value>
          <FormControl
            type="date"
            defaultValue={props.carReport.noneventDate
              ?.toISOString()
              .slice(0, 10)}
            onChange={(e) =>
              setAttr(
                Object.assign({}, attr, {
                  noneventDate: e.target.value
                    ? new Date(e.target.value)
                    : null,
                })
              )
            }
          />
          {getAttr("event")?.date ? (
            <HelpBlock>Vyber, pokud je jiné než datum konání závodu.</HelpBlock>
          ) : (
            <HelpBlock>{errors.noneventDate}</HelpBlock>
          )}
        </Value>
      </FormGroup>
    );
  };

  const carRow = () => {
    const select = () => {
      if (cars.isLoading) {
        return (
          <Select
            key="car-select-loading"
            isLoading={true}
            placeholder="Nahrávám..."
          />
        );
      }

      const options = cars.data.map((car) => {
        return {
          value: car.id,
          label: `${car.title} (${car.person.name})`,
          car: car,
        };
      });

      return (
        <Select
          key="car-select"
          options={options}
          defaultValue={
            getAttr("car") &&
            options.find((option) => option.car.id === getAttr("car").id)
          }
          onChange={(value) =>
            setAttr(Object.assign({}, attr, { car: value?.car }))
          }
          placeholder=""
          // Hack to void the dropdown menu being overwritten by below fields.
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 10 }),
          }}
        />
      );
    };

    return (
      <FormGroup key="car" validationState={errors.car && "error"}>
        <Label>Auto</Label>
        <Value>
          {select()}
          <HelpBlock>{errors.car}</HelpBlock>
        </Value>
      </FormGroup>
    );
  };

  const distanceRow = () => {
    return (
      <FormGroup key="distance" validationState={errors.distance && "error"}>
        <Label>Vzdálenost (tam i zpět)</Label>
        <Value>
          <InputGroup>
            <FormControl
              type="number"
              placeholder={props.carReport.distance}
              defaultValue={props.carReport.distance}
              onChange={(e) =>
                setAttr(Object.assign({}, attr, { distance: e.target.value }))
              }
            />
            <InputGroup.Addon>km</InputGroup.Addon>
          </InputGroup>
          <HelpBlock>{errors.distance}</HelpBlock>
        </Value>
      </FormGroup>
    );
  };

  const fuelRow = () => {
    return (
      <>
        <FormGroup key="fuel" validationState={errors.fuelPrice && "error"}>
          <Label>Cena PHM</Label>
          <Value>
            <InputGroup>
              <FormControl
                type="number"
                placeholder={props.carReport.fuelPrice}
                value={getAttr("fuelPrice") || ""}
                onChange={(e) =>
                  setAttr(
                    Object.assign({}, attr, {
                      fuelPrice: e.target.value,
                    })
                  )
                }
              />
              <InputGroup.Addon>Kč/l</InputGroup.Addon>
            </InputGroup>
            <HelpBlock>{errors.fuelPrice}</HelpBlock>
            {defaultFuelCost && (
              <HelpBlock>
                Cena {getAttr("car").diesel ? "dieselu" : "benzínu"} pro rok{" "}
                {year} daná vyhláškou je {formatCost(defaultFuelCost)}&nbsp;Kč.
                Pokud zadáš vyšší, odevzdej pak s vytištěným cesťákem i účtenku.
              </HelpBlock>
            )}
          </Value>
        </FormGroup>
      </>
    );
  };

  const feeParkingRow = () => {
    return (
      <>
        <FormGroup
          key="feeParking"
          validationState={errors.feeParking && "error"}
        >
          <Label>Parkovné</Label>
          <Value>
            <InputGroup>
              <FormControl
                type="number"
                placeholder={props.carReport.feeParking}
                defaultValue={props.carReport.feeParking}
                onChange={(e) =>
                  setAttr(
                    Object.assign({}, attr, {
                      feeParking: e.target.value,
                    })
                  )
                }
              />
              <InputGroup.Addon>Kč</InputGroup.Addon>
            </InputGroup>
            <HelpBlock>{errors.feeParking}</HelpBlock>
            {getAttr("feeParking") > 0 && (
              <HelpBlock>
                Účtenku pak odevzdej společně s vytištěným cesťákem.
              </HelpBlock>
            )}
          </Value>
        </FormGroup>
      </>
    );
  };

  const numPassengers = () => {
    const passengerTable = () => {
      const joins = getAttr("transportOffer")?.transportOfferJoins;
      if (!joins) return "";

      return (
        <FormGroup>
          <Label />
          <Value>
            <HelpBlock>
              <Row key="header">
                <Col xs={12}>
                  <b>Přihlášení cestující</b>
                </Col>
              </Row>
              {joins.map((join) => (
                <Row key={join.id}>
                  <Col xs={12}>
                    <span key="name">
                      {join.person ? join.person.name : join.extraPersonName}
                    </span>
                    <span key="direction" className="pull-right">
                      {join.direction === "both"
                        ? "tam i zpět"
                        : join.direction === "outward"
                        ? "tam"
                        : join.direction === "return"
                        ? "zpět"
                        : "?"}
                    </span>
                  </Col>
                </Row>
              ))}
            </HelpBlock>
          </Value>
        </FormGroup>
      );
    };

    return (
      <>
        <FormGroup
          key="numPassengersOutward"
          validationState={errors.numPassengersOutward && "error"}
        >
          <Label>Cestujících tam (vč. řidiče)</Label>
          <Value>
            <FormControl
              type="number"
              value={getAttr("numPassengersOutward") || ""}
              onChange={(e) =>
                setAttr(
                  Object.assign({}, attr, {
                    numPassengersOutward: e.target.value,
                  })
                )
              }
            />
            <HelpBlock>{errors.numPassengersOutward}</HelpBlock>
          </Value>
        </FormGroup>
        <FormGroup
          key="numPassengersReturn"
          validationState={errors.numPassengersReturn && "error"}
        >
          <Label>Cestujících zpět (vč. řidiče)</Label>
          <Value>
            <FormControl
              type="number"
              value={getAttr("numPassengersReturn") || ""}
              onChange={(e) =>
                setAttr(
                  Object.assign({}, attr, {
                    numPassengersReturn: e.target.value,
                  })
                )
              }
            />
            <HelpBlock>{errors.numPassengersReturn}</HelpBlock>
          </Value>
        </FormGroup>
        {passengerTable()}
      </>
    );
  };

  const noteRow = () => {
    return (
      <FormGroup key="note">
        <Label>Poznámka</Label>
        <Value>
          <FormControl
            type="text"
            defaultValue={props.carReport.note}
            onChange={(e) =>
              setAttr(Object.assign({}, attr, { note: e.target.value }))
            }
          />
        </Value>
      </FormGroup>
    );
  };

  const bankAccountRow = () => {
    return (
      <FormGroup key="bankAccount">
        <Label>Bankovní účet</Label>
        <Value>
          <FormControl
            type="text"
            defaultValue={props.carReport.bankAccount}
            onChange={(e) =>
              setAttr(Object.assign({}, attr, { bankAccount: e.target.value }))
            }
          />
        </Value>
      </FormGroup>
    );
  };

  const quotientRow = () => {
    if (!props.carReport.permissions?.quotient) return <></>;

    return (
      <FormGroup key="quotient">
        <Label>
          Příspěvek oddílu{" "}
          <i
            className="fa fa-unlock"
            data-show="tooltip"
            data-title="Pouze ouřada."
          />
        </Label>
        <Value>
          <InputGroup>
            <FormControl
              type="number"
              defaultValue={props.carReport.quotient}
              onChange={(e) =>
                setAttr(Object.assign({}, attr, { quotient: e.target.value }))
              }
            />
            <InputGroup.Addon>%</InputGroup.Addon>
          </InputGroup>
          <HelpBlock>Když nevyplníš, Olin spočte podle zaplněnosti.</HelpBlock>
        </Value>
      </FormGroup>
    );
  };
  return (
    <>
      {makeQueryErrorAlerts([cars])}
      {makeValidationErrorAlerts([mutation])}
      <Form horizontal>
        {eventRow()}
        {dateRow()}
        {carRow()}
        {numPassengers()}
        {distanceRow()}
        {fuelRow()}
        {feeParkingRow()}
        {noteRow()}
        {bankAccountRow()}
        {quotientRow()}
        <FormGroup key="submit">
          <Label />
          <Value>
            <div className="pull-right">
              <Button bsStyle="default" onClick={() => props.onClose()}>
                Zrušit
              </Button>{" "}
              <Button
                bsStyle="primary"
                disabled={mutation.isLoading || Object.keys(errors).length > 0}
                onClick={async () => {
                  const response = await mutate(attr);
                  if (response.data) props.onClose(response.data);
                }}
              >
                {attr.id ? "Upravit" : "Vytvořit"}
              </Button>
            </div>
          </Value>
        </FormGroup>
      </Form>
    </>
  );
};

CarReportForm.propTypes = {
  carReport: PropTypes.object,
};

CarReportForm.defaultProps = {
  carReport: {},
};

export default CarReportForm;
