import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Form from "react-bootstrap/lib/Form";
import Overlay from "react-bootstrap/lib/Overlay";
import Popover from "react-bootstrap/lib/Popover";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import styled from "styled-components";
import { useClient } from "jsonapi-react";

const DashedAnchor = styled.a`
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const State = {
  DEFAULT: 0,
  EDITING: 1,
  SUBMITTING: 2,
};

const Action = {
  OPEN: 0,
  UPDATE_PENDING_VALUE: 1,
  SUBMIT: 2,
  RESPONSE_OK: 3,
  RESPONSE_ERROR: 4,
  CLOSE: 5,
};

const reducer = (state, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Action.OPEN:
      newState.target = action.target;
      newState.pendingValue = action.value;
      newState.state = State.EDITING;
      break;
    case Action.UPDATE_PENDING_VALUE:
      newState.pendingValue = action.value;
      break;
    case Action.SUBMIT:
      newState.state = State.SUBMITTING;
      break;
    case Action.RESPONSE_OK:
      newState.pendingValue = action.value;
      newState.state = State.DEFAULT;
      break;
    case Action.RESPONSE_ERROR:
      newState.state = State.DEFAULT;
      break;
    case Action.CLOSE:
      newState.state = State.DEFAULT;
      break;
    default:
      throw `Unhandled action: ${action.type}`;
  }

  return newState;
};

const labelOrSelf = (any) => {
  const label = any.label;
  return label ? label : any;
};

const valueOrSelf = (any) => {
  const value = any.value;
  return value ? value : any;
};

const Editable = (props) => {
  const missingText = props.missingText ? props.missingText : props.label;

  if (!props.editable) {
    return props.currentValue ? labelOrSelf(props.currentValue) : "";
  }

  const [state, dispatch] = useReducer(reducer, {
    state: State.DEFAULT,
    pendingValue: props.currentValue,
    target: null,
    client: useClient(),
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: Action.SUBMIT });

    const { data, error, errors } = await state.client.mutate(props.url, {
      [props.patchKey]: state.pendingValue
        ? valueOrSelf(state.pendingValue)
        : null,
    });

    if (data) {
      dispatch({ type: Action.RESPONSE_OK, value: data[props.patchKey] });
      props.onResponse(data);
      return;
    }

    if (error) {
      alert(error.detail || error.title);
    } else if (errors.length > 0) {
      alert(errors[0].detail || errors[0].title);
    } else {
      alert("Chyba");
    }

    dispatch({ type: Action.RESPONSE_ERROR });
  };

  const onClose = (e) => {
    e.preventDefault();
    dispatch({ type: Action.CLOSE });
  };

  return (
    <>
      <DashedAnchor
        style={{
          ...(state.state === State.SUBMITTING
            ? {
                backgroundColor:
                  state.state === State.SUBMITTING ? "yellow" : null,
              }
            : {
                transition: "background-color 1400ms ease-out",
              }),
          ...(props.dashedLink ? { borderBottom: "dashed 1px #0088cc" } : {}),
        }}
        disabled={state.state === State.SUBMITTING}
        onClick={(e) => {
          dispatch({
            type: Action.OPEN,
            target: e.target,
            value: props.currentValue,
          });
        }}
      >
        {props.currentValue && props.showValue
          ? labelOrSelf(props.currentValue)
          : missingText}
      </DashedAnchor>
      <Overlay
        show={state.state === State.EDITING}
        placement="top"
        container={this}
        target={state.target}
        containerPadding={20}
      >
        <Popover id="editable-input" title={props.label}>
          <Form onSubmit={onSubmit}>
            <DivFlex>
              <props.inputComponent
                options={props.options}
                currentValue={state.pendingValue}
                setPendingValue={(value) => {
                  dispatch({ type: Action.UPDATE_PENDING_VALUE, value: value });
                }}
              />
              <span>&nbsp;</span>
              <ButtonGroup>
                <Button bsStyle="primary" bsSize="small" onClick={onSubmit}>
                  <i className="glyphicon glyphicon-ok" />
                </Button>
                <Button bsSize="small" onClick={onClose}>
                  <i className="glyphicon glyphicon-remove" />
                </Button>
              </ButtonGroup>
            </DivFlex>
          </Form>
        </Popover>
      </Overlay>
    </>
  );
};

Editable.defaultProps = {
  showValue: true,
  dashedLink: true,
};

Editable.propTypes = {
  currentValue: PropTypes.any,
  showValue: PropTypes.bool,
  inputComponent: PropTypes.any.isRequired,
  missingText: PropTypes.any,
  editable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  url: PropTypes.array.isRequired,
  patchKey: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
  dashedLink: PropTypes.bool,
};

export default Editable;
