import { useQuery } from "jsonapi-react";
import CarReportForm from "./CarReportForm";
import PropTypes from "prop-types";
import React from "react";
import { makeQueryErrorAlerts } from "../util/Errors";

const CarReportNew = (props) => {
  // Standard car report with transport offer.
  const transportOffer = useQuery(
    props.transportOfferId && [
      "transportOffers",
      props.transportOfferId,
      {
        include: [
          "car",
          "event",
          "person",
          "people",
          "transportOfferJoins.person",
        ],
      },
    ]
  );

  // Car report from our own event.
  const event = useQuery(props.eventId && ["events", props.eventId]);

  if (transportOffer.isLoading || event.isLoading) return <></>;

  {
    const errors = makeQueryErrorAlerts([transportOffer, event]);
    if (errors.length) return errors;
  }

  return (
    <CarReportForm
      carReport={
        props.transportOfferId
          ? {
              transportOffer: transportOffer.data,
              event: transportOffer.data.event,
              car: transportOffer.data.car,
            }
          : props.eventId
          ? {
              event: event.data,
            }
          : {}
      }
      onClose={(carReport) => {
        if (carReport) {
          window.location.href = carReport.linkSelf;
        } else {
          window.history.back();
        }
      }}
    />
  );
};

CarReportNew.propTypes = {
  transportOfferId: PropTypes.number,
  eventId: PropTypes.number,
};

export default CarReportNew;
