import { useQuery } from "jsonapi-react";
import Badge from "react-bootstrap/lib/Badge";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Col from "react-bootstrap/lib/Col";
import Constants from "../util/Constants";
import Fade from "react-bootstrap/lib/Fade";
import LoadBank from "./LoadBank";
import Months from "./Months";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import PaymentModal from "./PaymentModal";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import RestoreScrolling from "../util/RestoreScrolling";
import Row from "react-bootstrap/lib/Row";
import Table from "react-bootstrap/lib/Table";
import Tooltip from "react-bootstrap/lib/Tooltip";
import styled from "styled-components";
import { useUrlHashState } from "../util/History";
import { makeQueryErrorAlerts } from "../util/Errors";
import { Modal } from "react-bootstrap";

const TdRight = styled.td`
  text-align: right;
`;

const Attr = (props) => {
  return <Col sm={props.size}>{props.children}</Col>;
};

const PaymentRow = (props) => {
  const constants = useContext(Constants);
  const [showEdit, setShowEdit] = useState(false);

  const currentPersonId = props.payment.person?.id;
  const currentEventId = props.payment.event?.id;

  const bank = () => {
    if (props.payment.bank) {
      return (
        <>
          <Attr size={3} key="payee">
            <code>{props.payment.payee}</code>
          </Attr>
          <Attr size={1} key="vs">
            <code>{props.payment.variableSymbol || "-"}</code>
          </Attr>
        </>
      );
    } else {
      return (
        <Attr size={4} key="pseudo">
          <div className="pull-right">
            <Badge>Pseudoplatba</Badge>
          </div>
        </Attr>
      );
    }
  };

  const edit = () => {
    if (!props.editable) return;
    return (
      <>
        <PaymentModal
          show={showEdit}
          onClose={() => setShowEdit(false)}
          payment={props.payment}
          people={props.people}
          ourEvents={props.ourEvents}
        />
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="edit">Upravit nebo smazat</Tooltip>}
        >
          <a
            role="button"
            onClick={(e) => {
              if (showEdit) return;
              setShowEdit(true);
            }}
          >
            <i className="fa fa-edit" />
          </a>
        </OverlayTrigger>
      </>
    );
  };

  const amount = () => {
    const value = props.payment.amount;
    return (
      <span className={value > 0 ? "text-success" : "text-danger"}>
        <b>{value.toFixed(0)}&nbsp;Kč</b>
      </span>
    );
  };

  const note = () => {
    return (
      <Attr size={3} key="note">
        {props.payment.note}
      </Attr>
    );
  };

  const person = () => {
    const person = props.people.find((person) => person.id == currentPersonId);
    if (!person) return;
    return (
      <Attr size={3} key="person">
        <a href={person.linkSelf}>
          {person.name} ({person.regNumber})
        </a>
      </Attr>
    );
  };

  const target = () => {
    const label = constants.paymentTargets[props.payment.target];
    if (!label) return;
    return (
      <Attr size={2} key="target">
        <Badge>{label}</Badge>
      </Attr>
    );
  };

  const event = () => {
    const event = props.ourEvents.find((event) => event.id == currentEventId);
    if (!event) return;
    return (
      <Attr size={3} key="event">
        <a href={event.linkSelf}>{event.title}</a>
      </Attr>
    );
  };

  const eventClub = () => {
    const value = props.payment.eventClub;
    if (!value) return;
    return (
      <Attr size={1} key="eventClub">
        {value}
      </Attr>
    );
  };

  const eventRegNumber = () => {
    const value = props.payment.eventRegNumber;
    if (!value) return;
    return (
      <Attr size={1} key="eventRegNumber">
        {value}
      </Attr>
    );
  };

  return (
    <>
      <TdRight key="edit">{edit()}</TdRight>
      <td key="attributes">
        <Row>
          {bank()}
          {note()}
          {person()}
          {target()}
          {event()}
          {eventClub()}
          {eventRegNumber()}
        </Row>
      </td>
      <TdRight key="amount">{amount()}</TdRight>
    </>
  );
};

const PaymentTable = (props) => {
  const now = new Date();

  // State.
  const [month, setMonth] = useUrlHashState(
    "month",
    now.getFullYear() == props.year ? now.getMonth() + 1 : 1
  );
  const [showNewPayment, setShowNewPayment] = useState(false);
  const [hideAssigned, setHideAssigned] = useUrlHashState(
    "hideAssigned",
    false
  );
  const [showPositive, setShowPositive] = useUrlHashState("showPositive", 0);

  // Queries.
  const payments = useQuery([
    "payments",
    {
      include: ["person", "event"],
      filter: {
        in_year: props.year,
        in_month: month,
      },
    },
  ]);
  const ourEvents = useQuery([
    "events",
    {
      filter: {
        oursNotStageNotPart: true,
      },
      sort: ["date"],
    },
  ]);
  const people = useQuery("people");

  const atCurrentMonth =
    now.getFullYear() == props.year && now.getMonth() + 1 == month;

  const table = () => {
    const errors = makeQueryErrorAlerts([payments, ourEvents, people]);
    if (errors.length) {
      return (
        <tr key="error">
          <td colSpan="3" style={{ textAlign: "center" }}>
            {errors}
          </td>
        </tr>
      );
    }

    const loading =
      payments.isLoading || ourEvents.isLoading || people.isLoading;

    if (loading) {
      return (
        <tr key="loading">
          <td colSpan="3" style={{ textAlign: "center" }}>
            <i
              className="fa fa-spinner fa-spin"
              data-show="tooltip"
              data-title="Načítá se..."
            />
          </td>
        </tr>
      );
    }

    if (payments.data.length == 0) {
      return (
        <tr key="empty">
          <td colSpan="3" style={{ textAlign: "center" }}>
            Žádné platby
          </td>
        </tr>
      );
    }

    let lastShownPaymentsDate = null;
    let rows = [];

    payments.data.forEach((payment) => {
      const hide =
        (showPositive < 0 && payment.amount > 0) ||
        (showPositive > 0 && payment.amount < 0) ||
        (hideAssigned && (payment.person || payment.event));

      if (!hide) {
        const formatDate = (payment) => {
          if (payment === null) return null;
          const date = payment.timestamp;
          return `${date.getDate()}.\u00a0${date.getMonth() + 1}.`;
        };

        const current = formatDate(payment);
        if (current !== lastShownPaymentsDate) {
          lastShownPaymentsDate = current;
          rows.push(
            <tr key={`date-${current}`} style={{ backgroundColor: "#f9f9f9" }}>
              <td />
              <td colSpan="2">
                <b>{current}</b>
              </td>
            </tr>
          );
        }
      }

      rows.push(
        <Fade
          key={payment.id}
          in={!hide}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <tr>
            <PaymentRow
              payment={payment}
              people={people.data}
              ourEvents={ourEvents.data}
              editable={props.editable}
            />
          </tr>
        </Fade>
      );
    });

    return (
      <>
        <RestoreScrolling />
        {rows}
      </>
    );
  };

  const modal = () => {
    if (ourEvents.isLoading || people.isLoading) {
      return <></>;
    }

    return (
      <PaymentModal
        show={showNewPayment}
        people={people.data}
        ourEvents={ourEvents.data}
        onClose={() => setShowNewPayment(false)}
      />
    );
  };

  const newPayment = () => {
    if (!props.editable) return;
    return (
      <Button
        bsStyle="primary"
        disabled={showNewPayment || !atCurrentMonth}
        onClick={() => setShowNewPayment(true)}
      >
        <i className="fa fa-pencil" /> Nová platba
      </Button>
    );
  };

  return (
    <>
      <ButtonToolbar>
        <ButtonGroup>
          <Months
            maxMonth={now.getFullYear() == props.year ? now.getMonth() + 1 : 12}
            selected={month}
            onChange={(month) => setMonth(month)}
          />
          <Button
            value="onlyPositive"
            active={showPositive > 0}
            onClick={() => setShowPositive(showPositive > 0 ? 0 : 1)}
          >
            <i className="fa fa-plus-square" /> Kladné
          </Button>
          <Button
            value="onlyNegative"
            active={showPositive < 0}
            onClick={() => setShowPositive(showPositive < 0 ? 0 : -1)}
          >
            <i className="fa fa-minus-square" /> Záporné
          </Button>
          <Button
            value="hideAssigned"
            active={hideAssigned}
            onClick={() => setHideAssigned(!hideAssigned)}
          >
            <i className="fa fa-eye-slash" /> Skrýt již přiřazené
          </Button>
        </ButtonGroup>
        <ButtonGroup className="pull-right">
          <LoadBank disabled={!atCurrentMonth} />
          {newPayment()}
        </ButtonGroup>
      </ButtonToolbar>
      <p />
      {/* TODO(vladimir.trebicky@gmail.com): Switch to rows for better performance. */}
      <Table condensed>
        <colgroup>
          <col width="0" />
          <col />
          <col width="0" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>
              <Row>
                <Attr size={3}>Účet</Attr>
                <Attr size={1}>VS</Attr>
                <Attr size={3}>Pozn.</Attr>
                <Attr size={5}>Závod / Člen DKP (klub, účel, atp.)</Attr>
              </Row>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{table()}</tbody>
      </Table>
      {modal()}
    </>
  );
};

PaymentTable.propTypes = {
  year: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default PaymentTable;
