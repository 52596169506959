import MenuItem from "react-bootstrap/lib/MenuItem";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import PropTypes from "prop-types";
import React from "react";

const Months = (props) => {
  const months = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  const selected = props.selected;
  const items = months.map((name, index) => {
    if (index + 1 > props.maxMonth) return;
    return (
      <MenuItem key={name} onClick={() => props.onChange(index + 1)}>
        {name}
      </MenuItem>
    );
  });
  return (
    <DropdownButton
      id="months"
      bsStyle="danger"
      title={
        <span>
          <i className="fa fa-calendar" /> {months[selected - 1]}
        </span>
      }
    >
      {items}
    </DropdownButton>
  );
};

Months.propTypes = {
  selected: PropTypes.number.isRequired,
  maxMonth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

Months.defaultProps = {
  maxMonth: 12,
};

export default Months;
