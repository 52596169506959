import { historyGetKey, historySetKey } from "./History";
import React, { useEffect } from "react";

const RestoreScrolling = () => {
  const key = "RestoreScrolling";

  useEffect(() => {
    const scrolling = historyGetKey(key);
    if (scrolling) {
      window.scrollTo(scrolling.x, scrolling.y);
    }

    const save = () =>
      historySetKey(key, {
        x: window.pageXOffset,
        y: window.pageYOffset,
      });

    window.addEventListener("unload", save);

    return save;
  }, []);

  return <></>;
};

export default RestoreScrolling;
