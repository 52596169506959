/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { ApiClient, ApiProvider } from "jsonapi-react";
import Constants from "util/Constants";
import schema from "util/Schema";
import ReactOnRails from "react-on-rails";
import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://f9e96ab7ab7242e3b0a8e2dbc9d92ab0@o962374.ingest.sentry.io/5910764",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // NOTE(vladimir.trebicky@gmail.com): This seems to be a paid feature.
  tracesSampleRate: 0.0,
});

ReactOnRails.registerStore({
  api: () =>
    new ApiClient({
      url: "/api",
      schema: schema,
      headers: {
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
    }),
  constants: (props) => props,
});

const WithProviders = (Component) => {
  return (props) => (
    <Constants.Provider value={ReactOnRails.getStore("constants")}>
      <ApiProvider client={ReactOnRails.getStore("api")}>
        <Component {...props} />
      </ApiProvider>
    </Constants.Provider>
  );
};

import BalanceEntryTable from "../components/BalanceEntryTable";
import CarReport from "../components/CarReport";
import CarReportNew from "../components/CarReportNew";
import PaymentTable from "../components/PaymentTable";

ReactOnRails.register({
  BalanceEntryTable: WithProviders(BalanceEntryTable),
  CarReport: WithProviders(CarReport),
  CarReportNew: WithProviders(CarReportNew),
  PaymentTable: WithProviders(PaymentTable),
});
